import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/product',
    name: 'ProductPage',
    component: () => import('@/views/ProductPage.vue'),
  },
  {
    path: '/rolunk',
    name: 'AboutPage',
    component: () => import('@/views/AboutPage.vue'),
  },
  {
    path: '/kapcsolat',
    name: 'ContactPage',
    component: () => import('@/views/ContactPage.vue'),
  },
  {
    path: '/gyakori-kerdesek',
    name: 'FAQPage',
    component: () => import('@/views/FAQPage.vue'),
  },
  {
    path: '/kategoria/:id',
    name: 'CategoryPage',
    component: () => import('@/views/CategoryPage.vue'),
  },
  {
    path: '/blog/:id',
    name: 'BlogPage',
    component: () => import('@/views/BlogPage.vue'),
  },
  {
    path: '/kosar',
    name: 'CartPage',
    component: () => import('@/views/CartPage.vue'),
  },
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: () => import('@/views/CheckoutPage.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.cartItemCount > 0) {
        next();
      } else {
        next('/');
      }
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: () => import('@/views/NotFoundPage.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (open.value) {
    open.value = false
  }
  next()
})

export default router;