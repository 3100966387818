<template>
  <div id="app">
    <NavbarPage />
    <router-view />
    <FooterPage />
  </div>
</template>

<script>
import NavbarPage from './components/Navbar.vue';
import FooterPage from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavbarPage,
    FooterPage
  }
}
</script>